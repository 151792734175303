export const useApiFavorite = (id: string) => {
    const favoritesCount = useState<number>('favoritesCount');
    return {
        add: async (favoriteId: string, type: number): Promise<string|null> => {
            return await mainFetch(`client/${id}/favorite`, {
                method: 'POST',
                body: { id: favoriteId, type }
            }).then((response) => {
                response = response.data.value??response.error.value?.data;
                if (response.error.code === 201) {
                    favoritesCount.value += 1;
                    return response.favorite.id;
                }
                return null;
            });
        },
        delete: async (favoriteId: string) => {
            return await mainFetch(`client/${id}/favorite/${favoriteId}`, {
                method: 'DELETE'
            }).then((response) => {
                response = response.data.value??response.error.value?.data;
                if (response.error.code === 200) {
                    favoritesCount.value -= 1;
                    return true;
                }
                return false;
            });
        }
    };
}
